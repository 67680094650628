<template>
  <ERow v-intersect="onVisibilityChange" class="pa-3">
    <ECol
      v-if="simNumber && !readOnly"
      cols="12"
      offset-md="0"
      class="py-0 pl-5"
      offset-sm="8"
    >
      <ValidationObserver ref="obsSendSms" v-slot="{ passes }" class="mb-n6">
        <v-form
          ref="form"
          class="form-width"
          @submit.prevent="passes(handleSubmit)"
        >
          <ERow>
            <ECol cols="2" class="pa-0">
              <ValidationProvider
                v-slot="{ errors }"
                mode="lazy"
                name="Messagecommand"
                rules="sms_command"
              >
                <v-combobox
                  v-model="smsMessageCommand"
                  class="pa-0 pr-1"
                  :items="messages"
                  label="Type SMS command here..."
                  :error-messages="errors"
                ></v-combobox>
              </ValidationProvider>
            </ECol>
            <ECol cols="2" class="pa-0">
              <v-btn
                class="pa-0"
                type="submit"
                color="primary"
                :loading="loading.send"
              >
                Send
              </v-btn>
            </ECol>
            <ECol cols="8" class="pa-0">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="float-right"
                    :loading="loading.refresh"
                    v-bind="attrs"
                    v-on="on"
                    @click="getSmsHistory"
                  >
                    <v-icon>fas fa-sync-alt</v-icon>
                  </v-btn>
                </template>
                <span>Reload sms history</span>
              </v-tooltip>
            </ECol>
          </ERow>
        </v-form>
      </ValidationObserver>
    </ECol>
    <ECol cols="12" class="pa-0 ma-0">
      <v-simple-table
        dense
        fixed-header
        height="auto"
        class="custom-data-table"
      >
        <template #default>
          <thead class="v-data-table-header">
            <tr>
              <th
                v-for="(item, index) in SmsHeadings"
                :key="index"
                :class="item.class"
              >
                {{ item.column }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(smsData, index) in simSmsHistory" :key="index">
              <td class="text-left">
                {{ formatDate(smsData.insertedAt) }}
              </td>
              <td class="text-center">
                <v-chip v-if="smsData.type == 'MO'" label small class="ma-1">
                  Incoming
                </v-chip>
                <v-chip
                  v-else
                  color="green"
                  label
                  class="ma-1"
                  small
                  text-color="white"
                >
                  Outgoing
                </v-chip>
              </td>
              <td class="text-center">
                <span style="text-transform: capitalize">
                  {{ smsData.status }}
                </span>
              </td>
              <td class="text-left">
                <EReadMore :content="smsData.text" :max-length="60" />
              </td>
              <td class="text-center">
                {{ formatDate(smsData.deliveryDatetime) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </ECol>
  </ERow>
</template>

<script>
import { SmsCommands, SmsStatuses } from "@evercam/shared/types/sim"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  props: {
    simNumber: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SmsHeadings: [
        { column: "Send at", class: "text-left" },
        { column: "Type", class: "text-center" },
        { column: "Status", class: "text-center" },
        { column: "Message", class: "text-left" },
        { column: "Delivered at", class: "text-center" },
      ],
      simSmsHistory: [],
      smsMessageCommand: SmsCommands.Status,
      messages: Object.values(SmsCommands),
      isComponentVisible: false,
      loading: {
        refresh: false,
        send: false,
      },
    }
  },
  watch: {
    simNumber: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSmsHistory()
        }
      },
    },
    simSmsHistory(history) {
      if (
        history.length &&
        this.isComponentVisible &&
        [SmsStatuses.Pending, SmsStatuses.Accepted].includes(history[0].status)
      ) {
        this.$setTimeout(this.getSmsHistory, 2000)
      }
    },
  },
  methods: {
    async getSmsHistory() {
      try {
        this.loading.refresh = true
        const response = await AdminApi.sms.getSimHistory(this.simNumber)
        this.simSmsHistory = response.singleSimSms
      } catch (error) {
        this.$notifications.error({
          text: "Could not load SIM History!",
          error,
        })
      } finally {
        this.loading.refresh = false
      }
    },
    onVisibilityChange(entries, observer, isIntersecting) {
      this.isComponentVisible = isIntersecting
    },
    handleSubmit() {
      this.$setTimeout(this.sendSms)
    },
    async sendSms() {
      try {
        this.loading.send = true
        const response = await AdminApi.sms.sendSms(this.simNumber, {
          smsMessage: this.smsMessageCommand,
        })
        if (parseInt(response.status) == 0) {
          this.$notifications.success(
            "Your message has been sent successfully."
          )
          this.smsMessageCommand = SmsCommands.Status
          this.$refs.obsSendSms.reset()
          this.getSmsHistory()
        } else {
          this.$notifications.error({
            text: response.errorText,
          })
        }
      } catch (error) {
        this.$notifications.error({
          text: "Failed to send the command!",
          error,
        })
      } finally {
        this.loading.send = false
      }
    },
  },
}
</script>
