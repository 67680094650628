<template>
  <div
    ref="wrapper"
    v-resize-observer="onPlayerDimensionsChange"
    class="position-relative"
    :style="wrapperStyle"
  >
    <v-progress-linear
      v-if="isLoadingHours"
      color="light-blue"
      value="100"
      indeterminate
    ></v-progress-linear>
    <HoursPicker
      ref="hoursPicker"
      class="mb-2"
      :hours="availableHoursByDay"
      :selected-hour="selectedHour"
      @change="onHourChange"
    />
    <EvercamPlayer
      :key="JSON.stringify(previewInterval)"
      :width="width ?? playerWidth"
      :height="height ?? playerHeight"
      :camera="cameraDialogStore.camera"
      :start="previewInterval.start"
      :has-live="false"
      :is-playing="isPlaying"
      :end="previewInterval.end"
      :selected-timestamp="playerSelectedTimestamp"
      :auth-token="accountStore.token"
      :disable-play-button="disablePlayButton"
      disable-play-pause-animation
      :play-on-click="false"
      :preload="true"
      :initial-snapshot-quality="720"
      :with-zoom-slider="false"
      show-snapshot-quality
      v-bind="$attrs"
      @update-playback="isPlaying = $event"
      @on-image-load="onImageLoaded"
      @snapshots-fetched="onSnapshotsFetched"
      @on-frame-props-change="(e) => $emit('on-frame-props-change', e)"
      @snapshot-changed="onPlayerSnapshotChange"
    >
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </EvercamPlayer>
  </div>
</template>

<script>
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import HoursPicker from "@/components/HoursPicker"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useAccountStore } from "@/stores/account"
import EvercamPlayer from "@evercam/shared/components/EvercamPlayer.vue"
export default {
  components: {
    HoursPicker,
    EvercamPlayer,
  },
  props: {
    value: {
      type: String,
      default: () => null,
    },
    selectedDay: {
      type: String,
      default: () => null,
    },
    disablePlayButton: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    height: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isPlaying: false,
      isLoadingHours: false,
      availableHoursByDay: [],
      userSelectedHour: null,
      playerHeight: "100%",
      playerWidth: "100%",
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useAccountStore),
    timezone() {
      return this.cameraDialogStore.camera?.timezone || "Europe/Dublin"
    },
    wrapperStyle() {
      if (this.width || this.height) {
        return {
          width: this.width,
          height: this.height,
        }
      } else {
        return {
          width: this.playerWidth,
          height: this.playerHeight,
        }
      }
    },
    selectedHour() {
      if (![null, undefined].includes(this.userSelectedHour)) {
        return this.userSelectedHour
      }

      return this.$moment.tz(this.selectedDay, this.timezone)?.hours()
    },
    previewInterval() {
      return {
        start: this.$moment
          .tz(this.selectedDay, this.timezone)
          .set("hour", this.selectedHour)
          .startOf("hour")
          .utc()
          .format(),
        end: this.$moment
          .tz(this.selectedDay, this.timezone)
          .set("hour", this.selectedHour)
          .endOf("hour")
          .utc()
          .format(),
      }
    },
    playerSelectedTimestamp() {
      return this.$moment.tz(this.selectedDay, this.timezone).format()
    },
  },
  watch: {
    selectedDay: {
      handler() {
        this.fetchAvailableHoursForDay()
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onPlayerDimensionsChange()
    })
  },
  methods: {
    onPlayerDimensionsChange() {
      if (this.width || this.height) {
        return
      }
      const { width } = this.$refs.wrapper.getBoundingClientRect()

      if (!width) {
        this.$setTimeout(this.onPlayerDimensionsChange)

        return
      }

      const top = this.$refs.hoursPicker.$el.getBoundingClientRect().top
      this.playerHeight = window.innerHeight - top - 50

      this.playerWidth = width
      this.$emit("image-dimensions", {
        width: this.playerWidth,
        height: this.playerHeight,
      })
    },
    onPlayerSnapshotChange(snapshot) {
      this.$emit("input", snapshot?.createdAt)
    },
    onSnapshotsFetched(snapshots = []) {
      if (snapshots.length) {
        this.$emit("input", snapshots[0]?.createdAt)
      }
    },
    onHourChange(hour) {
      this.userSelectedHour = hour
    },
    async fetchAvailableHoursForDay() {
      this.availableHoursByDay = []
      const m = this.$moment(this.selectedDay)
      const year = m.format("YYYY")
      const month = m.format("MM")
      const day = m.format("DD")

      try {
        this.isLoadingHours = true
        const { hours } = await EvercamApi.recordings.availableHours({
          cameraId: this.cameraDialogStore.camera?.exid,
          year,
          month,
          day,
        })
        this.availableHoursByDay = hours
        this.isLoadingHours = false
      } catch (err) {
        this.$errorTracker.save(err)
      }
    },
    onImageLoaded(e) {
      this.cameraDialogStore.imageElement = e
      this.$emit("image-load", e)
    },
  },
}
</script>
