<template>
  <v-card flat>
    <v-card-text
      ref="wrapper"
      v-resize-observer="onPlayerDimensionsChange"
      class="deleteWrapper"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <ERow no-gutters class="d-flex justify-center position-relative">
          <div :style="{ 'max-width': '500px' }">
            <RecordingsDatePicker v-model="selectedDay" />
          </div>
        </ERow>
        <ERow>
          <ECol ref="startDateContainer" cols="12" lg="6" md="6">
            <RecordingsTimePicker
              v-if="selectedDay"
              v-model="startDate"
              :selectedDay="selectedDay"
              :height="500"
              :width="wrapperWidth - 5"
            />
            <ValidationProvider
              v-slot="{ errors }"
              name="startDate"
              :rules="{
                required: true,
                date: true,
                compare_dates: ['@endDate', 'startDate'],
                date_format: ['YYYY-MM-DDTHH:mm:ssZ'],
              }"
            >
              <v-text-field
                v-model="startDate"
                :error-messages="errors"
                outlined
                dense
                label="Start Date"
                class="mt-2"
                readonly
              />
            </ValidationProvider>
          </ECol>
          <ECol ref="endDateContainer" cols="12" lg="6" md="6">
            <RecordingsTimePicker
              v-if="selectedDay"
              v-model="endDate"
              :selectedDay="selectedDay"
              :height="500"
              :width="wrapperWidth - 5"
            />
            <ValidationProvider
              v-slot="{ errors }"
              name="endDate"
              :rules="{
                required: true,
                date: true,
                compare_dates: ['@startDate', 'endDate'],
                date_format: ['YYYY-MM-DDTHH:mm:ssZ'],
              }"
            >
              <v-text-field
                v-model="endDate"
                :error-messages="errors"
                outlined
                dense
                label="End Date"
                class="mt-2"
                readonly
              />
            </ValidationProvider>
          </ECol>
          <ECol cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              :loading="isLoading"
              :disabled="invalid || isLoading"
              color="error"
              @click="deleteCloudRecordings()"
            >
              Delete
            </v-btn>
          </ECol>
        </ERow>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script>
import RecordingsTimePicker from "@/components/cameraTabs/RecordingsTimePicker"
import RecordingsDatePicker from "@/components/cameraTabs/RecordingsDatePicker"
import { extend } from "vee-validate"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    RecordingsTimePicker,
    RecordingsDatePicker,
  },
  props: {
    exid: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      isLoading: false,
      selectedDay: null,
      wrapperWidth: 0,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
  },
  watch: {
    startDate() {
      this.$nextTick(() => {
        this.$refs.obs.validate()
      })
    },
    endDate() {
      this.$nextTick(() => {
        this.$refs.obs.validate()
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onPlayerDimensionsChange()
    })
    extend("compare_dates", {
      params: ["target", "fieldName"],
      validate: (value, { target, fieldName }) => {
        if (fieldName === "endDate") {
          return this.$moment(value).isSameOrAfter(this.$moment(target))
        }

        return this.$moment(value).isSameOrBefore(this.$moment(target))
      },
      message: (field, { target }) => {
        const splitWords = (text) =>
          this.toTitleCase(text.replace(/([a-z0-9])([A-Z])/g, "$1 $2"))

        return `${splitWords(field)} field should be ${
          field === "startDate" ? "less" : "greater"
        } than ${splitWords(target)} field or equal`
      },
    })
  },
  methods: {
    async deleteCloudRecordings() {
      this.endDate = this.$moment(this.endDate)
        .add({ seconds: 2 })
        .format("YYYY-MM-DDTHH:mm:ssZ")

      if (
        await this.$confirmDialog.open({
          title: "Delete snapshots?",
          message: "This will delete snapshots within the specified interval!",
        })
      ) {
        try {
          this.isLoading = true
          await AdminApi.cameras.deleteSnapshots(this.exid, {
            fromDate: `${this.startDate}`,
            toDate: `${this.endDate}`,
            adminEmail: this.accountStore.email,
            adminFullname: `${this.accountStore.firstname} ${this.accountStore.lastname}`,
          })
          this.$notifications.success(
            "Cloud Recordings have been deleted successfully."
          )
          this.startDate = null
          this.endDate = null
          this.$nextTick(() => {
            this.$refs.obs.reset()
          })
        } catch (error) {
          this.$notifications.error({ text: "Delete Snapshots failed!", error })
        } finally {
          this.isLoading = false
        }
      } else {
        this.isLoading = false
      }
    },
    onPlayerDimensionsChange() {
      const wrapperBounds = this.$refs.deleteWrapper

      if (!wrapperBounds) {
        this.$setTimeout(this.onPlayerDimensionsChange)

        return
      }
      const { width } = wrapperBounds.getBoundingClientRect()

      this.wrapperWidth = width
    },
  },
}
</script>
